//require('./bootstrap');

/*
{{ HForm::script('vendor/jquery/jquery.min.js') }}
{{ HForm::script('vendor/jquery.appear/jquery.appear.min.js') }}
{{ HForm::script('vendor/jquery.easing/jquery.easing.min.js') }}
{{ HForm::script('vendor/bootstrap/js/bootstrap.min.js') }}
{{ HForm::script('vendor/common/common.min.js') }}
{{ HForm::script('js/theme.js') }}
{{ HForm::script('vendor/rs-plugin/js/jquery.themepunch.tools.min.js') }}
{{ HForm::script('vendor/rs-plugin/js/jquery.themepunch.revolution.min.js') }}
{{ HForm::script('js/theme.init.js') }}
*/


//JQuery
window.$ = window.jQuery = require('jquery'); //, require('jquery-ui');

//Boostrap
require('./bootstrap.min.js');


//Vue.js
require('./vue');


//require('../vendor/jquery.appear/jquery.appear.min');
//require('../vendor/jquery.easing/jquery.easing.min');

//require('../vendor/rs-plugin/js/jquery.themepunch.tools.min');
//require('../vendor/rs-plugin/js/jquery.themepunch.revolution.min');


//require('../vendor/common/common');

//require('./MyTheme');
//require('./theme.init');

//Icons
//import '@fortawesome/fontawesome-free/js/all.min';

//Wufoo Formular
//require('./wufoo');